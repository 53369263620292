import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { openSnackbar } from 'store/slices/snackbar';
// import axios from 'utils/axios';
// eslint-disable-next-line import/no-unresolved, import/extensions

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: window.localStorage.getItem('isLoggedIn'),
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    const role = localStorage.getItem('role');

    const getInstituteHeader = async () => {
        const response = await axios.get('/v1/institutes/get', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('serviceToken')}`,
                'Content-Type': 'application/json'
            }
        });
        console.log(response, 'response');
        localStorage.setItem('instituteName', response.data.data.instituteName);
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const response = await axios.get('/v1/user/me', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('serviceToken')}`,
                        'Content-Type': 'application/json'
                    }
                });
                console.log(response, 'response');
                localStorage.setItem('instituteId', response.data.data.accessScope);
                localStorage.setItem('collegeId', response.data.data.collageId);
                localStorage.setItem('emailVerified', response.data.data.emailVerified);
                localStorage.setItem('userTypes', response.data.data.userType);
                localStorage.setItem('userName', response.data.data.userName);
                dispatch({
                    ...state,
                    type: LOGIN,
                    payload: {
                        isLoggedIn: window.localStorage.getItem('isLoggedIn'),
                        user: response.data.data
                    }
                });

                // if (serviceToken && verifyToken(serviceToken)) {
                //     setSession(serviceToken);
                //     const response = await axios.get('/api/account/me');
                //     const { user } = response.data.data;
                //     dispatch({
                //         type: LOGIN,
                //         payload: {
                //             isLoggedIn: localStorage.getItem('isLoggedIn'),
                //             user
                //         }
                //     });
                // } else {
                //     dispatch({
                //         type: LOGOUT
                //     });
                // }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
        if (role !== 'ROLE_HIRER') {
            getInstituteHeader();
        }
    }, []);

    const login = async (username, password) => {
        const response = await axios.post(`/v1/auth/signin`, {
            username,
            password
        });
        const { token, user } = response.data.data;
        console.log(response, 'response>>>>>');
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('role', response.data.data.role[0].roleName);
        localStorage.setItem('collegeId', response.data.data.collageId);
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('id', response.data.data.id);
        localStorage.setItem('userTypes', response.data.data.userType);
        localStorage.setItem('userName', response.data.data.userName);
        setSession(response.data.data.token);
        if (response.data.data.firstLogin === true) {
            navigate(`/ustmentrar/password/change/${response.data.data.token}`);
        } else if (response.data.data.phoneVerified === false && response.data.data.role[0].roleName === 'ROLE_STUDENT_ADMISSION') {
            navigate('/otp/page');
        } else {
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: response.data.data
                }
            });
            // eslint-disable-next-line no-unused-expressions
            window.location.reload();
        }
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        // localStorage.removeItem('isLoggedIn');
        localStorage.clear();
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (Password, token) => {
        const data = {
            password: Password,
            username: token
        };
        axios
            .post(`/v1/auth/password/change`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response, 'responseresponse');
                localStorage.setItem('isLoggedIn', true);
                localStorage.setItem('role', response.data.data.role[0].roleName);
                localStorage.setItem('collegeId', response.data.data.collageId);
                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('id', response.data.data.id);
                localStorage.setItem('userTypes', response.data.data.userType);
                localStorage.setItem('userName', response.data.data.userName);
                setSession(response.data.data.token);
                if (response.data.data.firstLogin === false) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: response.data.data
                        }
                    });
                    window.location.reload();
                }
            })
            .catch((error) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Somthing went wrong',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            });
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
