import React, { useMemo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Customization from '../Customization';
import Header from './Header';
import HorizontalBar from './HorizontalBar';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
    // ...theme.typography.mainContent
    // ...(!open && {
    //     borderBottomLeftRadius: 0,
    //     borderBottomRightRadius: 0,
    //     transition: theme.transitions.create('margin', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.shorter
    //     }),
    //     [theme.breakpoints.up('md')]: {
    //         marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
    //         width: `calc(100% - ${drawerWidth}px)`,
    //         marginTop: layout === 'horizontal' ? 135 : 88
    //     },
    //     [theme.breakpoints.down('md')]: {
    //         marginLeft: '20px',
    //         width: `calc(100% - ${drawerWidth}px)`,
    //         padding: '16px',
    //         marginTop: 88
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         marginLeft: '10px',
    //         width: `calc(100% - ${drawerWidth}px)`,
    //         padding: '16px',
    //         marginRight: '10px',
    //         marginTop: 88
    //     }
    // }),
    // ...(open && {
    //     transition: theme.transitions.create('margin', {
    //         easing: theme.transitions.easing.easeOut,
    //         duration: theme.transitions.duration.shorter
    //     }),
    //     marginLeft: layout === 'horizontal' ? '20px' : 0,
    //     marginTop: layout === 'horizontal' ? 135 : 88,
    //     borderBottomLeftRadius: 0,
    //     borderBottomRightRadius: 0,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     [theme.breakpoints.up('md')]: {
    //         marginTop: layout === 'horizontal' ? 135 : 88
    //     },
    //     [theme.breakpoints.down('md')]: {
    //         marginLeft: '20px',
    //         marginTop: 88
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         marginLeft: '10px',
    //         marginTop: 88
    //     }
    // })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, drawerType, layout } = useConfig();

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        } else {
            dispatch(openDrawer(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerType]);

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header = useMemo(
        () => (
            <>
                <Header />
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd]
    );

    return (
        <div style={{ display: 'flex' }}>
            <Main theme={theme} open={drawerOpen} layout={layout}>
                <Container maxWidth={false} {...(!container && { sx: { px: { xs: 0 } }, p: 0 })}>
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Container>
            </Main>
        </div>
    );
};

export default MainLayout;
